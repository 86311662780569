// import tippy from 'tippy.js';

// Register Alpine Magic for $tooltip
document.addEventListener('alpine:init', () => {
    // Magic: $tooltip
    Alpine.magic('tooltip', el => message => {
        if (document.querySelector('[x-tooltip]') !== null) {

            import('tippy.js').then(tippy => {
                import('tippy.js/dist/tippy.css'); // optional for styling

                let instance = tippy.default(el, { content: message, trigger: 'manual' });

                instance.show();

                setTimeout(() => {
                    instance.hide();

                    setTimeout(() => instance.destroy(), 150);
                }, 5000)
            });
        }
    });

    // Directive: x-tooltip
    Alpine.directive('tooltip', (el, { expression }) => {
        if (document.querySelector('[x-tooltip]') !== null) {
            import('tippy.js').then(tippy => {
                import('tippy.js/dist/tippy.css'); // optional for styling
                tippy.default(el, {
                    content: expression,
                    trigger: 'mouseenter click',
                    allowHTML: true,
                    delay: [0, 100],
                    maxWidth: 340,
                })
            });
        }
    })
})
