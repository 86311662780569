var tabs = document.querySelector('.weather-tabs');
    var btn = document.querySelector('.weather-tabs .more-button');
    var counter = document.querySelector('.weather-tabs .counter');
    var vlinks = document.querySelector('.weather-tabs .visible-links');
    var hlinks = document.querySelector('.weather-tabs .hidden-links');

    var breaks = [];

function updateTabs() {
    var availableSpace = btn.classList.contains('hidden') ? tabs.clientWidth : tabs.clientWidth - btn.clientWidth - 30;
    // The visible list is overflowing the tabs
    if (vlinks.clientWidth > availableSpace) {
        // Record the width of the list
        breaks.push(vlinks.clientWidth);

        // Move item to the hidden list
        var item = vlinks.lastElementChild;
        item.querySelector('button').classList.add('!border-r-0', 'bg-blue-50' , 'border-b-[4px]', '!border-blue-100');
        hlinks.prepend(item);

        // Show the dropdown btn
        if (btn.classList.contains('hidden')) {
            btn.classList.remove('hidden');
        }
    } else {
        // There is space for another item in the tabs
        if (availableSpace > breaks[breaks.length - 1]) {
            // Move the item to the visible list
            var item = hlinks.firstElementChild;
            item.querySelector('button').classList.remove('!border-r-0', 'bg-blue-50' , 'border-b-[4px]', '!border-blue-100');
            vlinks.append(item);
            breaks.pop();
        }

        // Hide the dropdown btn if hidden list is empty
        if (breaks.length < 1) {
            btn.classList.add('hidden');
            //hlinks.classList.add('hidden');
        }
    }
    // Keep counter updated
    counter.setAttribute("count", breaks.length);
    // Recur if the visible list is still overflowing the tabs
    if (vlinks.clientWidth > availableSpace) {
        updateTabs();
    }
}

// Listen for the custom tab-clicked event
document.addEventListener('tab-clicked', function(event) {
    //console.log('Tab clicked event received:', event.detail);

    // Get the more button
    var moreButton = document.querySelector('.weather-tabs .more-button');

    // If a hidden tab was clicked, update the more button's appearance
    if (event.detail.isHidden && moreButton) {
        //console.log('Hidden tab was clicked:', event.detail.title);

        // Add a custom attribute to track which hidden tab is selected
        moreButton.setAttribute('data-selected-hidden-tab', event.detail.title);

        // Simply dispatch a custom event to close the dropdown
        document.dispatchEvent(new CustomEvent('close-dropdown'));
    }
});

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// Debounced version of updateTabs
const debouncedUpdateTabs = debounce(updateTabs, 250); // 250ms delay

// Window listeners with debouncing
window.addEventListener('resize', debouncedUpdateTabs);


// Initial call to updateTabs (no need to debounce this one)
debouncedUpdateTabs();
