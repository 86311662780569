import type Hls from 'hls.js';

export function initializeVideoPlayer(element: HTMLElement): void {
    const video = element.querySelector('video') as HTMLVideoElement;
    const playButton = element.querySelector('[data-video\\.playbutton]');

    const listener = async () => {
        await loadSourceFromHls(video, video.getAttribute('data-src')!);
        video.setAttribute('controls', '');
        video.play();

        playButton.removeEventListener('click', listener);
        playButton.remove();
    }

    playButton.addEventListener('click', listener);
}


async function loadSourceFromHls(video: HTMLVideoElement, src: string): Promise<Hls> {
    const { default: Hls } = await import('hls.js');

    const hls = new Hls();
    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(src);
    })

    return hls;
}
